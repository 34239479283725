<template>
	<div class="container">
		<div class="paper p-4">
			<div class="h5_b_line">
				<h3>W3 DAO {{store.state.lang.stackeText}}</h3>
				<!-- <h6>0 {{store.state.lang.days}}{{store.state.lang.nextRebase}}</h6> -->
			</div>
			<div class="row stake-text">
				<div class="col-sm-3 mt-3">
					<div class="h5_b_line">
						<div>{{store.state.lang.TotalValueStaked}}</div>
						<h3 class="mt-3">${{TotalValueStaked}}</h3>
					</div>
				</div>
				<div class="col-sm-3 mt-3">
					<div class="h5_b_line">
						<div>W3B {{store.state.lang.APY}}</div>
						<h3 class="mt-3">266%</h3>
					</div>
				</div>
				<div class="col-sm-3 mt-3">
					<div class="h5_b_line">
						<div>W3C {{store.state.lang.APY}}</div>
						<h3 class="mt-3">298.43%</h3>
					</div>
				</div>
				<div class="col-sm-3 mt-3">
					<div class="h5_b_line">
						<div>{{store.state.lang.CurrentIndex}}</div>
						<h3 class="mt-3">{{CurrentIndex}} W3C</h3>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-sm-8 offset-sm-2 ls-flex-ajc stake-bar">
					<div class="ls-flex-1 ls-flex-ajc stake-bar-item" :class="{'ls-bg':stakeIndex==0}"
						@click="changeStakeIndex(0)">{{store.state.lang.stackeText}}</div>
					<div class="ls-flex-1 ls-flex-ajc stake-bar-item ml-3 mr-3" :class="{'ls-bg':stakeIndex==1}"
						@click="changeStakeIndex(1)">{{store.state.lang.UnStake}}</div>
					<div class="ls-flex-1 ls-flex-ajc stake-bar-item" :class="{'ls-bg':stakeIndex==2}"
						@click="changeStakeIndex(2)">{{store.state.lang.Wrap}}</div>
				</div>
				
				<div class="ls-flex-ac col-sm-8 offset-sm-2 mt-4 max-bar" v-show="stakeIndex==0 || stakeIndex==1">
					<div class="ls-flex-ajc max-bar-item ls-flex-1" @click="changeMaxIndex(0)" :class="{'active':maxIndex==0}">W3B</div>
					<div class="is-pc-80"></div>
					<div class="ls-flex-ajc max-bar-item ls-flex-1" @click="changeMaxIndex(1)" :class="{'active':maxIndex==1}">W3C</div>
				</div>
				
				<div class="col-sm-8 offset-sm-2 mt-4 ls-flex-ajc" v-show="stakeIndex==0 || stakeIndex==1">
					<el-input v-model="stakeAmount" style="color: #ffffff;" size="large" placeholder="Input Amount" />
					<div class="max-btn ls-flex-ajc" v-if='stakeAmount' @click="stakeAmount = ''">
						<img :src="require('../../assets/img/icon_c.png')" alt="">
					</div>
					<div class="max-btn ls-flex-ajc" @click="stakeOrUnstakeMax()">{{store.state.lang.MAX}}</div>
				</div>
				
				<div class="col-sm-8 offset-sm-2 ls-flex-ajc mt-4" v-show="stakeIndex==0">
					<div class="ls-flex-1 ls-flex-ajc" v-if="maxIndex == 0">
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-if="!approveW3Bstate" @click="openApproveBox('W3B',0)">
							{{store.state.lang.approve}} W3B
						</div>
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-else @click="stakeW3B()">
							{{store.state.lang.stackeText}} W3B
						</div>
					</div>
					<div class="ls-flex-1 ls-flex-ajc" v-if="maxIndex == 1">
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-if="!approveW3Cstate" @click="openApproveBox('W3C',1)">
							{{store.state.lang.approve}} W3C
						</div>
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-else @click="stakeW3C()">
							{{store.state.lang.stackeText}} W3C
						</div>
					</div>
					
				</div>

				<div class="col-sm-8 offset-sm-2 ls-flex-ajb mt-4" v-show="stakeIndex==1">
					<div class="ls-flex-1 ls-flex-ajc" v-if="maxIndex == 0">
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-if="w3bStakeBalance" @click="withdrawW3B()">
							{{store.state.lang.UnStake}} W3B</div>
						<div class="ls-flex-1 ls-flex-ajc ls-gray" v-else>{{store.state.lang.UnStake}} W3B</div>
					</div>
					
					<div class="ls-flex-1 ls-flex-ajc" v-if="maxIndex == 1">
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-if='w3cStakeBalance' @click="withdrawW3C()">
							{{store.state.lang.UnStake}} W3C</div>
						<div class="ls-flex-1 ls-flex-ajc ls-gray" v-else>{{store.state.lang.UnStake}} W3C</div>
					</div>
				</div>
				
				<!-- <div class="col-sm-8 offset-sm-2 ls-flex-ajc mt-4" v-show="stakeIndex==1">
					<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg">Withdraw All</div>
				</div> -->

				<div class="col-sm-12" style="padding: 0;" v-show="stakeIndex==2">
					<div class="col-sm-8 offset-sm-2 ls-flex-ajc mt-4">
						<el-input v-model="wrapAmount" @input="getInitWrap" size="large"
							placeholder="Input W3B Amount" />
						<div v-if='wrapAmount' class="max-btn ls-flex-ajc" @click="wrapAmount = ''">
							<img :src="require('../../assets/img/icon_c.png')" alt="">
						</div>
						<div class="max-btn ls-flex-ajc" @click="wrapMax()">{{store.state.lang.MAX}}</div>
					</div>
					<div class="col-sm-12 text-center mt-4">
						<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#620000"
							class="bi bi-arrow-down" viewBox="0 0 16 16">
							<path fill-rule="evenodd"
								d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
						</svg>
					</div>
					<div class="col-sm-8 offset-sm-2 ls-flex-ajc mt-4">
						<div class="ls-flex-1 ls-flex-ac get-ces">{{store.state.lang.YouWillGet}} {{initWrapNum}} W3C
						</div>
					</div>
					<div class="col-sm-8 offset-sm-2 ls-flex-ajc mt-4">
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-if="!approveWrapState"
							@click="openApproveBox('Wrap',2)">{{store.state.lang.approve}}</div>
						<div class="ls-flex-1 ls-flex-ajc stake-btn ls-bg" v-else @click="wrapW3B()">
							{{store.state.lang.Wrap}} W3C</div>
					</div>
				</div>
				<div class="col-sm-8 offset-sm-2 ls-flex-ajb mt-4 balance-item">
					<div>W3B {{store.state.lang.Balance}}</div>
					<div>{{w3bBalanceFm}}</div>
				</div>
				<div class="col-sm-8 offset-sm-2 ls-flex-ajb balance-item">
					<div>W3C {{store.state.lang.Balance}}</div>
					<div>{{w3cBalanceFm}}</div>
				</div>
				<div class="col-sm-8 offset-sm-2 ls-flex-ajb balance-item">
					<div>W3B {{store.state.lang.stackeText}}{{store.state.lang.Balance}}</div>
					<div>{{w3bStakeBalanceFm}} W3B</div>
				</div>
				<div class="col-sm-8 offset-sm-2 ls-flex-ajb balance-item">
					<div>W3C {{store.state.lang.stackeText}}{{store.state.lang.Balance}}</div>
					<div>{{w3cStakeBalanceFm}} W3C</div>
				</div>
				<div class="col-sm-8 offset-sm-2 ls-flex-ajb balance-item">
					<div>{{store.state.lang.Earned}}W3C</div>
					<div>{{earnedW3CFm}} W3C</div>
				</div>
			</div>
		</div>

		<div class="paper p-4 mt-4">
			<h3>{{store.state.lang.Calculator}}</h3>
			<h5 class="mt-4">{{store.state.lang.EstimateReturn}}</h5>
			<div class="row">
				<div class="col-sm-8 offset-sm-2">
					<el-input v-model="calVal" @input="getCalVal" size="large" placeholder="Enter the amount W3C" />
					<div class="ls-flex-1 ls-flex-ajc mt-3">
						<div class="ls-flex-1 ls-flex-ajc cal-btn" @click="getBarIndex(index)"
							:class="{'ls-bg':calBarIndex==index}" v-for="(item,index) in claBarList" :key="index">
							{{item.text}}
						</div>
					</div>
				</div>
			</div>
			<h5 class="mt-4 ls-flex-ajb">
				<div>{{store.state.lang.NextRewardYield}}</div>
				<div class="slider-val ls-flex-ajc">{{sliderVal/1000}}%</div>
			</h5>
			<div class="col-sm-8 offset-sm-2 ls-flex-ajb mt-4">
				<el-slider v-model="sliderVal" :max="300" @change="changeSlider" :show-tooltip="false"
					:format-tooltip="formatTooltip" />

			</div>
			<div class="col-sm-8 offset-sm-2 ls-flex-ajb mt-4 get-ces">
				<div>W3C {{store.state.lang.Balance}}</div>
				<div>{{calBalance}}</div>
			</div>
			<!-- <div class="col-sm-8 offset-sm-2 ls-flex-ajb mt-4 get-ces">
				<div>{{store.state.lang.Total}} USD</div>
				<div>$ {{totalUsd}}</div>
			</div> -->
		</div>
	</div>
	
	<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>
</template>

<script setup>
	import {ref,watch,onMounted} from 'vue';
	import {useStore} from 'vuex';
	import {W3CAddr,W3BAddr,stackeAddr,wrapAddr,mintAddr} from '@/utils';
	import {approveAbi} from '@/utils/approveAbi.json';
	import {stackeAbi} from '@/utils/stackeAbi.json';
	import {wrapAbi} from '@/utils/wrapAbi.json';
	import {mintAbi} from '@/utils/mintAbi.json';
	import Web3 from "web3";
	import formate from "@/utils/formate.js";
	import {ElMessageBox,ElMessage} from 'element-plus';
	import verify from '@/utils/verify.js';
	import comModal  from "@/components/com-modal/com-modal.vue";
	import { thousandth } from '@clownlee/utils';
	
	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');
	
	
	const store = useStore();

	const stakeAmount = ref('');
	const wrapAmount = ref('');
	const stakeIndex = ref(0);
	const calVal = ref('');
	const calBalance = ref(0);
	const sliderVal = ref(0);
	const totalUsd = ref(0);
	const calBarIndex = ref(0);
	const claBarList = ref([{
			text: '7 Days',
			days: 7
		},
		{
			text: '1 Month',
			days: 30
		},
		{
			text: '6 Month',
			days: 180
		},
		{
			text: '1 Year',
			days: 365
		},
	]);

	const approveW3Cnum = ref(0);
	const approveW3Cstate = ref(false);
	const approveW3Bnum = ref(0);
	const approveW3Bstate = ref(false);
	const approveWrapNum = ref(0);
	const approveWrapState = ref(false);

	const w3bBalance = ref(0);
	const w3bBalanceFm = ref(0);
	const w3bStakeBalance = ref(0);
	const w3bStakeBalanceFm = ref(0);
	const w3cBalance = ref(0);
	const w3cBalanceFm = ref(0);
	const w3cStakeBalance = ref(0);
	const w3cStakeBalanceFm = ref(0);
	const earnedW3C = ref(0);
	const earnedW3CFm = ref(0);
	const wrapBalance = ref(0);
	const wrapBalanceFm = ref(0);

	const CurrentIndex = ref(0);
	const TotalValueStaked = ref(0);
	const crsPrice = ref(0);
	const crsFromatePrice = ref(0);

	const initWrapNum = ref(0);
	
	const maxIndex = ref(0);

	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}

	const approveW3bContract = new web3js.eth.Contract(approveAbi, W3BAddr);
	const approveW3cContract = new web3js.eth.Contract(approveAbi, W3CAddr);
	const approveWrapContract = new web3js.eth.Contract(approveAbi, wrapAddr);

	const stackeContract = new web3js.eth.Contract(stackeAbi, stackeAddr);
	const wrapContract = new web3js.eth.Contract(wrapAbi, wrapAddr);
	const mintContract = new web3js.eth.Contract(mintAbi, mintAddr);
	
	const wrapMax = () => {
		wrapAmount.value = web3js.utils.fromWei(wrapBalance.value,'ether');
	}
	
	const stakeOrUnstakeMax = ()=>{
		if(stakeIndex.value ==0){
			if(maxIndex.value==0){
				stakeAmount.value = web3js.utils.fromWei(w3bBalance.value,'ether');
			}
			else{
				stakeAmount.value = web3js.utils.fromWei(w3cBalance.value,'ether');
			}
		}
		else if(stakeIndex.value ==1){
			if(maxIndex.value==0){
				stakeAmount.value = web3js.utils.fromWei(w3bStakeBalance.value,'ether');
			}
			else{
				stakeAmount.value = web3js.utils.fromWei(w3cStakeBalance.value,'ether');
			}
		}
		else{
			
		}
	}

	//获取授权额度
	const getCoinApproveNum = () => {
		// W3B
		approveW3bContract.methods.allowance(store.state.account, stackeAddr).call().then((res) => {
			approveW3Bnum.value = res;
			approveW3Bstate.value = res > 0 ? true : false;
		});
		// W3C
		approveW3cContract.methods.allowance(store.state.account, stackeAddr).call().then((res) => {
			approveW3Cnum.value = res;
			approveW3Cstate.value = res > 0 ? true : false;
		});
		// Wrap
		approveW3bContract.methods.allowance(store.state.account, wrapAddr).call().then((res) => {
			approveWrapNum.value = res;
			approveWrapState.value = res > 0 ? true : false;
		});
	}

	//获取token余额
	const getCoinBalance = () => {
		// W3B
		approveW3bContract.methods.balanceOf(store.state.account).call().then((res) => {
			w3bBalance.value = res;
			w3bBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
		});
		// W3C
		approveW3cContract.methods.balanceOf(store.state.account).call().then((res) => {
			w3cBalance.value = res;
			w3cBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);

		});
		//Wrap 
		approveW3bContract.methods.balanceOf(store.state.account).call().then((res) => {
			wrapBalance.value = res;
			wrapBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);

		});
		// W3BStake
		stackeContract.methods.w3bBalances(store.state.account).call().then((res) => {
			w3bStakeBalance.value = res;
			w3bStakeBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);

		});
		// W3CStake
		stackeContract.methods.getUseableW3c(store.state.account).call().then((res) => {
			w3cStakeBalance.value = res;
			w3cStakeBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);

		});
		// Earned CRS
		stackeContract.methods.viewEarnedW3C(store.state.account).call().then((res) => {
			earnedW3C.value = res;
			earnedW3CFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 6);
		});
	}

	const getGons = () => {
		//查选膨胀系数,/10^18
		stackeContract.methods.gons().call().then((res) => {
			CurrentIndex.value = formate.formatDecimal(res / Math.pow(10, 18), 4);
			//查Total Value Staked
			stackeContract.methods._totalStakeBalances().call().then((result) => {
				let a = web3js.utils.fromWei(result, 'ether') * CurrentIndex.value * crsFromatePrice.value;
				TotalValueStaked.value = thousandth(formate.formatDecimal(a, 4));
			})
		})
	}

	onMounted(()=>{
		//获取CRS价格
		mintContract.methods.getW3CPrice().call().then((res) => {
			crsPrice.value = res;
			crsFromatePrice.value = formate.formatDecimal(web3js.utils.fromWei(crsPrice.value, 'ether'), 6);
			getGons();
		});
		
		if (store.state.account) {
			//执行获取token余额
			getCoinBalance();
			getCoinApproveNum();
		}
	})

	watch(store.state,(news,olds)=>{
		if (store.state.account) {
			//执行获取token余额
			getCoinBalance();
			getCoinApproveNum();
		}
	})

	//查询w3C总投资额
	const w3cInwardAmount = () => {
		stackeContract.methods.w3cInwardAmount(store.state.account).call().then((res) => {
			console.log(res);
		}, (err) => {
			console.log(err);
		});
	}

	//查询w3C总提现额
	const w3cClaimAmount = () => {
		stackeContract.methods.w3cClaimAmount(store.state.account).call()
			.then((res) => {
				console.log(res);
			}, (err) => {})
	}

	//查询总共赚取的W3C
	const viewEaredW3C = () => {
		stackeContract.methods.viewEarnedCRS(store.state.account).call()
			.then((res) => {
				console.log(res);
			}, (err) => {})
	}


	//质押W3B
	const stakeW3B = () => {
		if(!stakeAmount.value){
			ElMessage('Please input amount');
			return false;
		}
		if(!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let amount = web3js.utils.toWei(stakeAmount.value);
		
		if(BigInt(amount) > BigInt(w3bBalance.value)) {
			ElMessage('balance is not enough!');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Stake W3B';
		stateNum.value = 1;
		
		stackeContract.methods.stakeW3B(amount).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				stakeAmount.value = '';
				
				getGons();
				getCoinBalance();
				getCoinApproveNum();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	//质押W3c
	const stakeW3C = () => {
		if(!stakeAmount.value){
			ElMessage('Please input amount');
			return false;
		}
		
		if(!verify.verifyChainId(store.state.chainId)) {
			return false;
		}

		let amount = web3js.utils.toWei(stakeAmount.value);
		
		if(BigInt(amount) >BigInt(w3cBalance.value)) {
			ElMessage('balance is not enough!');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Stake W3C';
		stateNum.value = 1;
		
		stackeContract.methods.stakeW3C(amount).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				
				stakeAmount.value = '';
				
				getCoinBalance();
				getCoinApproveNum();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	//提现W3b
	const withdrawW3B = () => {
		if(!stakeAmount.value){
			ElMessage('Please input amount');
			return false;
		}
		
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let amount = web3js.utils.toWei(stakeAmount.value);
		
		if(BigInt(amount) >BigInt(w3bStakeBalance.value)) {
			ElMessage('W3B stake balance is not enough!');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Unstake W3B';
		stateNum.value = 1;
		
		stackeContract.methods.withdrawW3B(amount).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				
				stakeAmount.value = '';
				
				getCoinBalance();
				getCoinApproveNum();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	//提现W3c 包括收益
	const withdrawW3C = () => {
		if(!stakeAmount.value){
			ElMessage('Please input amount');
			return false;
		}
		
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let amount = web3js.utils.toWei(stakeAmount.value);
		
		if(BigInt(amount) > BigInt(w3cStakeBalance.value)) {
			ElMessage('W3C stake balance is not enough!');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Unstake W3C';
		stateNum.value = 1;
		
		stackeContract.methods.withdrawW3C(amount).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				
				stakeAmount.value = '';
				
				getCoinBalance();
				getCoinApproveNum();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	const wrapW3B = () => {
		if(!wrapAmount.value){
			ElMessage('Please input W3B amount');
			return false;
		}
		
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let amount = web3js.utils.toWei(wrapAmount.value);
		
		if(BigInt(amount) >BigInt(w3bBalance.value)) {
			ElMessage('W3B balance is not enough!');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Wrap';
		stateNum.value = 1;
		
		wrapContract.methods.warp(amount).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				
				wrapAmount.value = '';
				
				getCoinBalance();
				getCoinApproveNum();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	const getInitWrap = () => {
		initWrapNum.value = formate.formatDecimal(wrapAmount.value * 0.8, 2);
	}

	// 提现nft收益
	const withdrwaNft = (id) => {
		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Stake';
		stateNum.value = 1;
		
		stackeContract.methods.withdrwaNft(id).send({
				from: store.state.account
			})
			.then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
	}

	// 查询nft收益
	const viewEarndNft = (id) => {
		stackeContract.methods.viewEarndNFT(id).call()
			.then((res) => {
			}, (err) => {})
	}

	//授权W3B额度
	const approveW3B = (num) => {
		
		showModalState.value = true;
		StateInfo.value = 'Approve W3B';
		stateNum.value = 1;
		
		approveW3bContract.methods.approve(stackeAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			getCoinApproveNum();
			
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//授权W3C额度
	const approveW3C = (num) => {
		showModalState.value = true;
		StateInfo.value = 'Approve W3C';
		stateNum.value = 1;
		
		approveW3cContract.methods.approve(stackeAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			getCoinApproveNum();
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	//授权Wrap额度
	const approveWrap = (num) => {
		showModalState.value = true;
		StateInfo.value = 'Approve Wrap';
		stateNum.value = 1;
		
		approveW3bContract.methods.approve(wrapAddr, num).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			
			getCoinApproveNum();
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}


	//授权额度弹窗
	const openApproveBox = (name, index) => {
		if (!store.state.account) {
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}

		if (!verify.verifyChainId(store.state.chainId)) {
			return false;
		}
		
		let val = web3js.utils.toWei('1000000000000', 'ether');
		switch (index) {
			case 0:
				approveW3B(val);
				break;
			case 1:
				approveW3C(val);
				break;
			case 2:
				approveWrap(val);
				break;
			default:
		}

		// ElMessageBox.prompt('Please enter the ' + name + ' limit you want to authorize', 'Tip', {
		// 		confirmButtonText: 'OK',
		// 		cancelButtonText: 'Cancel',
		// 		cancelButtonClass: 'cancelBtnClass',
		// 		confirmButtonClass: 'confrimBtnClass',
		// 		customClass: 'msgClass',
		// 		inputPlaceholder: 'Input Amount',
		// 		inputPattern: /^\d*\.?\d+$/,
		// 		inputErrorMessage: 'Invalid Amount',
		// 	})
		// 	.then(({
		// 		value
		// 	}) => {
				
		// 	})
		// 	.catch(() => {

		// 	})
	}


	const changeStakeIndex = (index) => {
		stakeAmount.value = '';
		stakeIndex.value = index;
		maxIndex.value = 0;
	}
	
	const changeMaxIndex = (index) => {
		stakeAmount.value = '';
		maxIndex.value = index;
	}
	const formatTooltip = (val) => {
		return val / 100
	}

	const getBarIndex = (index) => {
		calBarIndex.value = index;
		initCalculator(calVal.value);
	}

	const getCalVal = (res) => {
		initCalculator(res);
	}

	const changeSlider = () => {
		initCalculator(calVal.value);
	}

	const initCalculator = (amount) => {
		if (amount) {
			let num = 1+sliderVal.value/1000000;
			calBalance.value = formate.formatDecimal(amount * Math.pow(num,claBarList.value[calBarIndex.value].days), 3);
		} else {
			calBalance.value = 0;
			totalUsd.value = 0;
		}

	}
</script>

<style scoped>
	.container {
		padding: 30px 15px;
		box-sizing: border-box;
	}

	.stake-bar,.max-bar {
		height: 42px;
		border-radius: 21px;
	}

	.stake-bar-item{
		height: 42px;
		border-radius: 21px;
		font-weight: 500;
		cursor: pointer;
		font-size: 18px;
		background: #000000;
		border: 1px solid #FF0000;
	}
	.stake-bar-item,.max-bar-item {
		height: 42px;
		border-radius: 21px;
		font-weight: 500;
		cursor: pointer;
		font-size: 18px;
	}
	.max-bar .max-bar-item {
		border: 1px solid #333;
		color: #999;
		background: #000000;
	}
	.max-bar .max-bar-item.active{
		background: #620000;
		color: #fff;
		border: 1px solid #620000;
	}
	.stake-btn,
	.cal-btn,
	.slider-val {
		height: 42px;
		border-radius: 21px;
		cursor: pointer;
		/* font-weight: 500; */
		font-size: 16px;
	}

	.cal-btn {
		border: 1px solid #FF0000;
		margin-right: 15px;
	}

	.cal-btn:last-child {
		margin-right: 0;
	}

	.slider-val {
		/* width: 25%; */
		margin-left: 10px;
		border-radius: 21px;
	}

	.stake-text {
		text-align: center;
	}

	.balance-item,
	.get-ces {
		/* height: 36px; */
		/* line-height: 36px; */
		padding: 10px 15px;
		box-sizing: border-box;
		font-size: 16px;
	}

	.balance-item:nth-child(2N+1) {
		background: #340000;
		border-radius: 18px;
	}

	.get-ces:nth-child(2N) {
		background: #340000;
		border-radius: 18px;
	}

	.max-btn {
		width: 80px;
		height: 40px;
		border-radius: 20px;
		background: #620000;
		cursor: pointer;
	}

	.max-btn img {
		width: 30px;
		height: 30px;
	}

	:deep .el-input__wrapper {
		background-color: #000000;
		box-shadow: 0 0 0 1px #000000;
		border-radius: 20px;
	}

	:deep .el-slider__runway {
		background: #000000 !important;
	}

	:deep .el-slider__bar {
		background: #620000 !important;
	}

	:deep .el-slider__button {
		background: #B90000;
		border: 3px solid #620000;
	}
	:deep .el-input__inner{
		color: #ffffff!important;
	}
	.is-pc-80{
		width: 80px;
	}
	@media (max-width:768px) {
		.stake-text {
			text-align: left;
		}
		.is-pc-80{
			width: 30px;
		}
		.cal-btn{
			margin-right: 5px;
			font-size: 14px;
		}
		.balance-item,
		.get-ces {
			font-size: 14px;
		}
	}
</style>
